<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="isLoading"
            @view="onView"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { DocumentSeriesAllocationModel } from '@/modules/document-series-allocation/document-series-allocation-model';

    const { fields } = DocumentSeriesAllocationModel;

    export default {
        name: 'DocumentSeriesAllocationListTable',
        data() {
            return {
                presenter: DocumentSeriesAllocationModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'documentSeriesAllocation/list/rows',
                loading: 'shared/loading',
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.created_date,
                    fields.allocated_series.extendField({ bold: true }),
                    fields.type_name,
                ]
            },
            isLoading () {
                return this.loading['documentSeriesAllocation/get'] ;
            },
        },
        methods: {
            ...mapActions({
                selectRecord: 'documentSeriesAllocation/form/select',
                deleteRecord: 'documentSeriesAllocation/form/remove',
            }),
            async onView(data) {
                this.$emit('view', data.item.allocation_recipient_id);
            },
        },
    };
</script>
