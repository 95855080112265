<template>
    <form class="form"
        novalidate="novalidate"
        id="st_manage_document_series_allocation_form"
    >
        <div class="form-group">
            <label>
                {{ fields.type_id.label }} *
            </label>
            <st-select
                v-model="model[fields.type_id.name]"
                :ref="fields.type_id.name"
                :field="fields.type_id"
                :formValidation="fv"
            />
        </div>
        <div class="upload-files">
            <label>{{ $t("DOCUMENT_SERIES_ALLOCATION.FIELDS.FILE") }} *</label>
            <b-form-file
                v-model="document"
                :browse-text="$t('GENERAL.BUTTON.UPLOAD_FILE')"
                placeholder=""
                id="file"
                accept=".pdf"
                ref="upload_documents"
                :state="documentState"
            ></b-form-file>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { mapActions, mapGetters } from 'vuex';

import { FormSchema } from '@/shared/form/form-schema';
import { DocumentSeriesAllocationModel } from '@/modules/document-series-allocation/document-series-allocation-model';

const { fields } = DocumentSeriesAllocationModel;
const formSchema = new FormSchema([
    fields.type_id,
]);

export default {
    name: 'ManageDocumentSeriesAllocationForm',
    data() {
        return {
            rules: formSchema.rules(),
            model: {},
            fields,
            fv: null,
            document: null,
            documentState: null,
        };
    },
    watch: {
        document(newFile) {
            this.documentState = newFile instanceof File;
        }
    },
    computed: {
        ...mapGetters({
            filters: 'documentSeriesAllocation/list/filters',
            uploadToken: 'documentSeriesAllocation/list/uploadAccessToken',
        }),
    },
    methods: {
        ...mapActions({
            doCreate: 'documentSeriesAllocation/form/create',
            doUpload: 'documentSeriesAllocation/form/upload',
            doFetch: 'documentSeriesAllocation/list/doFetch'
        }),
        clearForm() {
             this.model = formSchema.initialValues({});
             this.document = null;
        },
        hide() {
            this.$emit('cancelForm');
            this.clearForm();
        },
        async manage() {
            const validate = await this.fv.validate();
            this.validateDocument();
            if (validate === 'Valid' && this.documentState) {
                // Upload
                const formData = new FormData();
                formData.append('files', this.document);
                const uploadedFiles = await this.doUpload({
                    files: formData,
                    uploadAccessToken: this.uploadToken
                });
                const uplaodedFileId = uploadedFiles[0]?.id;
                if (uplaodedFileId) {
                    // Create
                    const payload = {
                        type_id: this.model.type_id,
                        allocation_recipient_id: uplaodedFileId,
                    };
                    this.doCreate(payload).then((data) => {
                        this.hide();
                        this.showSuccessModal(data);
                        this.$emit('submit');
                    });
                }
            }
        },
        validateDocument() {
            this.documentState = this.document instanceof File;
        },
        showSuccessModal(id) {
            const title = this.$t('DOCUMENT_SERIES_ALLOCATION.NOTIFIERS.CREATED_SUCCESS_TITLE');
            const text = `${this.$t('DOCUMENT_SERIES_ALLOCATION.NOTIFIERS.CREATED_SUCCESS_TEXT', {number: id})}`;
            this.$alert({
                type: 'success',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
    },
    created() {
        this.model = formSchema.initialValues();
    },
    mounted() {
        this.fv = createFormValidation('st_manage_document_series_allocation_form', this.rules);
    },
};
</script>
