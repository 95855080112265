import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import DateField from '@/shared/fields/date-field';
import { DocumentSeriesAllocationUserField } from '@/modules/document-series-allocation/document-series-allocation-user-field';
import { ActiveDocumentSeriesField } from '@/modules/document-series-allocation/active-document-series-field';


const label = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    allocated_series: new StringField('allocated_series', label('allocated_series'), {
        required: true,
    }, { placeholder: placeholder('allocated_series') }),
    created_by: new StringField('created_by', label('created_by')),
    created_by_filter: DocumentSeriesAllocationUserField.relationToOne(
        'created_by',
        label('created_by'),
        {},
        { placeholder: placeholder('created_by') },
        [],
        { asyncData: true }
    ),
    created_date: new DateField('created_date', label('created_date')),
    type_name: new StringField('type_name', label('type_name'), {}, { placeholder: placeholder('type_name') }),
    type_id: ActiveDocumentSeriesField.relationToOne(
        'type_id',
        label('type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('type_name') },
        [],
        { asyncData: true }
    ),
    
};

export class DocumentSeriesAllocationModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
